import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    Slider
    // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import PackageLazyLoader from "../../../components/src/PackageLazyLoader";
import {packageIcon} from "./assets";
import { styled } from "@material-ui/core/styles";
import "../../cfitinerarymanagement/assets/style.css"
const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#1764A0",
    height: 12,
    padding: "20px 0",
    "& .MuiSlider-thumb": {
        height: 25,
        width: 25,
        backgroundColor: "#fff",
        border: "1px solid #f7f7f7",
        boxShadow: "4px 4px 10px 0px #00000029",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "4px 4px 10px 0px #00000029",
            "@media (hover: none)": {
                boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
            },
        },
        "&:before": {
            boxShadow:
                "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
        },
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&::before": {
            display: "none",
        },
        "& *": {
            background: "transparent",
            color: "#000",
        },
    },
    "& .MuiSlider-track": {
        border: "none",
        height: 12,
        borderRadius: "10px"
    },
    "& .MuiSlider-rail": {
        opacity: 0.5,
        height: 12,
        boxShadow: "inset 0px 0px 4px -2px #000",
        backgroundColor: "#1764A0",
        borderRadius: "10px"
    },
}));
// Customizable Area End

import AllPackageController, {
    Props,
} from "./AllPackageController.web";
import PackageSearch from "../../cfitinerarymanagement/src/PackageSearch.web";

export default class AllPackage extends AllPackageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <PackageSearch data-test-id="PackageSearch" onSendData={this.handleChildDataFn} package={"AllPackage"} navigation={this.props.navigation} id={""} />
                <Box style={{position:"relative"}}>
                <div className="content-container" style={{
                            position: 'absolute',
                            top: '-100px',
                            width: '100%',
                        }}>
                <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 30px" }, }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                    <Box className="boxShadowDetail" style={{background:"#fff"}}>
                    <div className="filter-middle-block" data-test-id="middle-filters" style={{ display: "flex", flexDirection: "column", gap: "32px", paddingLeft: "28px", paddingRight: "28" }}>
                        <Box data-test-id="price-filter" sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                             <Typography style={{
                                fontFamily: "Inter, sans-serif",
                                fontWeight: 700,
                                fontSize: "24px",
                                marginBottom: "20px"
                            }}> Filter</Typography>

                            <Typography style={{
                                fontFamily: "Inter, sans-serif",
                                fontWeight: 700,
                                fontSize: "24px",
                                marginBottom: "-4px"
                            }}> Price Range</Typography>
                            <StyledSlider data-test-id="slider-Price" min={typeof this.state.minPrice === "number" ? this.state.minPrice : 0}
                                max={typeof this.state.maxPrice === "number" ? this.state.maxPrice : 100}
                                onChange={this.handlePriceChange} value={this.state.selectedPrice} />
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "-2px"
                            }}>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                }} className="price-range-content">INR {this.state.minPrice}</Typography>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                }} className="price-range-content">INR {this.state.selectedPrice}</Typography>
                            </Box>

                            <Typography style={{
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "24px",
    marginTop:"10px",
    marginBottom: "-4px"
}}>
    Duration
</Typography>
<StyledSlider 
    data-test-id="slider-Duration" 
    min={typeof this.state.minDuration === "number" ? this.state.minDuration : 2} 
    max={typeof this.state.maxDuration === "number" ? this.state.maxDuration : 7} 
    onChange={this.handleDurationChange} 
    value={this.state.selectedDuration} 
/>
<Box sx={{
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-2px"
}}>
    <Typography style={{
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
    }}>
        {this.state.minDuration}N
    </Typography>
    <Typography style={{
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
    }}>
        {this.state.selectedDuration}N
    </Typography>
</Box>


                        </Box>
                    </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                        <Box className="boxShadowDetail" style={{background:"#fff"}}>
    <Grid container spacing={2}>
        {this.state.ErrorMsg && 
        <Box data-test-id="ErrorMsg" sx={{display:"flex", justifyContent:"center", flexDirection:"column", width:"100%"}}>
             <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img src={packageIcon} alt="Package Icon"  style={{ 
          width: '200px',  
        }}  />
    </Box>
        <Box style={{background:"#fff", width:"100%", display:"block", textAlign:"center", paddingTop:"30px",borderRadius: "8px",
        }}>
           <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>{this.state.ErrorMsg}</Typography>
         </Box>
        </Box>
        }
        {this.state.dataAllPackage?.map((item:any)=>(
            <Grid item md={6}>
      <Box style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 10px', borderRadius: '16px',padding:"15px" }}>
      <div className="image-cardPkAll">
                    <PackageLazyLoader src={item.image} type="packageAll" />
                  </div>
          <Box style={{ padding: '16px 16px 0 16px' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <Typography variant="h6" style={{ fontWeight: '700', fontSize: '16px' }}>
                {item.name}
              </Typography>
              <Button variant="outlined"  style={{border:"1px solid rgba(23, 100, 160, 1)", color:"#000", borderRadius:"12px", fontWeight:700, fontSize:"14px"}}>
                6N/7D
              </Button>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
              <Box style={{marginTop:"0px"}}>
                <ul className="cardULLISting">
                    <li>Roundtrip Flights</li>
                    <li>Airport Transfers</li>
                    <li>Selected Meals</li>
                </ul>
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Box sx={{padding:{xs:"10px 0", sm:"10px 0", md:"30px 0"}}}>
                <Typography><span style={{fontSize:"16px", fontWeight:700, color:"#000"}}>${item.price}</span>
                <span style={{fontSize:"14px", fontWeight:500, color:"rgba(100, 116, 139, 1)"}}>/Person</span></Typography>
                <Typography style={{fontSize:"14px", fontWeight:500, color:"rgba(100, 116, 139, 1)", marginTop:"10px"}}><span>Total Price</span><span>- ${item.totalprice}</span></Typography>
                </Box>
               
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
        ))}
      
      
    </Grid>
                            </Box>
                            </Grid>
                    </Grid>
                    
                </Box>
                </div>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


// Customizable Area End

