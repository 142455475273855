import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon:any;
  isLoading:boolean;
  singlePackageData:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PackageDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSinglePackageApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      appliedCoupon:null,
      isLoading:false,
      singlePackageData:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getSinglePackageApiCallId) {
          this.singlePackageRes(responseJson)
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    let clientIDkey:string=this.props.navigation.getParam("id")
    if(clientIDkey!=""){
        this.getSinglePackage(clientIDkey);
    }
  }
  singlePackageRes=(responseJson:any)=>{
    if(!responseJson.errors && responseJson){
      this.setState({singlePackageData:responseJson.data, isLoading:false})
  }
  }
  getSinglePackage = (paramId:string) => {
    this.setState({
      isLoading: true,
    });

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getSingleBlogMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSinglePackageApiCallId = getSingleBlogMsg.messageId;

    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SinglePackageEndPoint+paramId
    );

    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getSingleBlogMsg.id, getSingleBlogMsg);
    return true;
  };
  handleApplyCoupon = (couponCode:any) => {
    if (this.state.appliedCoupon === couponCode) {
     this.setState({appliedCoupon:null})
    } else {
        this.setState({appliedCoupon:couponCode})
    }
  };

  getGridSize = (numImages:any) => {
    if (numImages === 1) {
        return 12; 
    } else if (numImages === 2) {
        return 6; 
    } else {
        return 4; 
    }
};
handleChildData = (data:any) => {
  data.map((item:any)=>{
     return {
       "image":item.attributes.image,
       "price": item.attributes.price,
       "package_type": item.attributes.package_type,
       "name":item.attributes.name,
      
     }
   })
};
  // Customizable Area End
}
